module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ileshmistry.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ileshmistry.com","short_name":"ileshmistry.com","start_url":"/","background_color":"#ffffff","theme_color":"#225885","display":"standalone","icon":"src/assets/logo-website.png","include_favicon":true,"cache_busting_mode":"name"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P4XNJ5J","includeInDevelopment":false},
    }]
